(function ($) {
  const rotationSpeed = 140;
  let interval;
  let counter = 0;
  let hardStop = false;
  const numberOfLoops = 5;
  const totalSlide = 4;

  const removeBg = () => {
    const $content = $("#content");
    $content.attr("data-bg-remove", "");
  };

  const reset = () => {
    clearInterval(interval);
    removeBg();
    hardStop = true;
  };

  const openTab = (id) => {
    $(`#nav-tab a[href="#${id}"]`).tab("show");
    removeBg();
  };

  const rotation = (index) => {
    const $content = $("#content");
    setTimeout(() => {
      $content.attr("class", `col-sm-12 bg-default bg-${index}`);
    }, index * 150);
  };

  const looper = () => {
    for (let index = 0; index < totalSlide; index++) {
      rotation(1 + index);
    }
  };

  const initiatingLoop = () => {
    setTimeout(() => {
      if (!hardStop) {
        looper();

        interval = setInterval(() => {
          counter++;
          if (counter !== numberOfLoops) {
            looper();
          }

          if (counter === numberOfLoops) {
            clearInterval(interval);
            setTimeout(() => {
              removeBg();
              openTab("profile");
            }, 3000);
          }
        }, rotationSpeed * totalSlide);
      }
    }, 2000);
  };

  const escapeHtml = (str) => {
    const map = {
      "&": "&amp;",
      "<": "&lt;",
      ">": "&gt;",
      '"': "&quot;",
      "'": "&#039;",
    };

    return str.replace(/[&<>"']/g, function (m) {
      return map[m];
    });
  };

  const thank = () => {
    $("#contactform-response").attr("class", "d-block");
    $("#contactform").attr("class", "d-none");
  };

  const submitForm = (el) => {
    // form
    const formData = new FormData(el);
    const inputs = Object.fromEntries(formData);
    const url = "/contact.php";

    fetch(url, {
      body: JSON.stringify(inputs),
      headers: {
        "Access-Control-Allow-Origin": "*",
        "content-type": "text/plain",
      },
      method: "POST",
      mode: "no-cors",
    })
      .then((response) => response.text())
      .then((result) => {
        console.log("Success:", result);
        thank();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  $(window).on("load", () => {
    const isMobile = window.innerWidth <= 767;
    if (isMobile) {
      removeBg();
      openTab("profile");
    } else {
      initiatingLoop();
    }
  });

  $(document).ready(() => {
    // nav binder
    $("ul.nav").click((e) => {
      reset();
      console.log(e);
    });

    console.log($("#contactform-response"));
    console.log($("#contactform"));

    // form binder
    $(document).on("submit", "form", function (e) {
      console.log("submitForm", e, this);
      e.preventDefault();
      e.stopPropagation();
      // code
      submitForm(this);

      return false;
    });

    // subnav binder
    $(".nav-link").on("click", function (e) {
      openTab(this.dataset.target);
    });
  });
})(jQuery);
